import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import classNames from "classnames";
import React, { ReactElement, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ThemeProvider } from "styled-components";

import ChoiceElementProps from "components/ChoiceElement/ChoiceElementProps";
import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";
import generateValidationRules from "utils/generateValidationRules";

import {
    Wrapper,
    Label,
    RadioItems,
    RadioLabel,
    RadioControl,
    RadioIcon,
    ErrorIcon,
    HelperText,
    Description,
    IconWrapper,
} from "./Radio.styled";

const Radio = ({
    label,
    id,
    identifier,
    elementName,
    description,
    displayOption = "Full",
    disabled = false,
    items,
    dependencies,
    className,
    validators,
    control,
    size = "medium",
    setValue,
    onChange,
    onFocus,
}: ChoiceElementProps): ReactElement => {
    const formContext = useFormContext();
    const unregister = formContext?.unregister || undefined;
    const defaultValue = useMemo(
        () =>
            (items &&
                items.length > 0 &&
                items.find((item) => item.checked && item.checked === true)
                    ?.value) ||
            "",
        [items],
    );

    const classes = classNames(
        className,
        validators?.map((validator) => {
            return validator?.model?.validationCssClass || "";
        }),
    );

    const rules =
        validators && validators?.length > 0
            ? generateValidationRules(validators)
            : undefined;

    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    useEffect(() => {
        if (elementName && setValue && unregister)
            if (visibleFromDependencies) setValue(elementName, defaultValue);
            else unregister(elementName);
    }, [
        defaultValue,
        elementName,
        setValue,
        unregister,
        visibleFromDependencies,
    ]);

    const radioIcon = (checked: boolean, error: boolean): JSX.Element => (
        <IconWrapper>
            {error && <ErrorIcon icon="error28" />}
            <RadioIcon $checked={checked} $error={error} />
        </IconWrapper>
    );

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    displayOption: displayOption,
                    visibleFromDependencies: visibleFromDependencies,
                    size,
                }}
            >
                {visibleFromDependencies && (
                    <Wrapper className={className}>
                        {(label || description) && (
                            <Label id={id || identifier}>
                                {label}
                                {description && (
                                    <HelperText>{description}</HelperText>
                                )}
                            </Label>
                        )}

                        <Controller
                            name={elementName || ""}
                            control={control}
                            defaultValue={defaultValue}
                            rules={rules}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <RadioItems
                                        aria-labelledby={id || identifier}
                                        className={classes}
                                        {...field}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                            value: string,
                                        ) => {
                                            field.onChange(event, value);
                                            if (onChange) {
                                                onChange(event, value);
                                            }
                                        }}
                                        onFocus={(
                                            event: React.FocusEvent<HTMLInputElement>,
                                        ) => {
                                            if (onFocus) {
                                                onFocus(event);
                                            }
                                        }}
                                    >
                                        {items?.map((item, index) => {
                                            return (
                                                <div key={`radio-${index}-div`}>
                                                    <RadioLabel
                                                        key={`radio-${index}`}
                                                        value={item.value}
                                                        checked={
                                                            field.value ===
                                                            item.value
                                                        }
                                                        label={
                                                            <>
                                                                {item.caption}
                                                                {item.description && (
                                                                    <Description
                                                                        disabled={
                                                                            item.disabled
                                                                        }
                                                                        required={
                                                                            item.required
                                                                        }
                                                                    >
                                                                        {
                                                                            item.description
                                                                        }
                                                                    </Description>
                                                                )}
                                                            </>
                                                        }
                                                        disabled={
                                                            item.disabled ||
                                                            disabled
                                                        }
                                                        control={
                                                            <RadioControl
                                                                role="none"
                                                                disableRipple
                                                                checkedIcon={radioIcon(
                                                                    true,
                                                                    !!error,
                                                                )}
                                                                icon={radioIcon(
                                                                    false,
                                                                    !!error,
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                    </RadioItems>
                                </>
                            )}
                        />
                    </Wrapper>
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(Radio);
